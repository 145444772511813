import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.scrollToActive()

    document.addEventListener('turbo:morph', this.scrollToActive.bind(this))
  }

  disconnect () {
    document.removeEventListener('turbo:morph', this.scrollToActive.bind(this))
  }

  scrollToActive () {
    const activeElement = this.element.querySelector('.d_tabs__link-active')
    if (activeElement) {
      this.element.scrollLeft = activeElement.offsetLeft - this.element.offsetWidth / 2 + activeElement.offsetWidth / 2
    }
  }
}
